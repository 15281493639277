<template lang="pug">
button.setting__button-blue(
  @click='synchronize()'
  :disabled='isSyncsUp || disableSync') {{ !isSyncsUp ? buttonText.syncText : buttonText.noSyncText }}
</template>

<script>
export default {
  name: 'ButtonSync',
  props: {
    initTime: {
      type: Number,
      default: null,
    },
    buttonStateName: {
      type: String,
      default: '',
    },
    boardId: {
      type: Number,
      default: 0,
    },
    actionFunc: {
      type: Function,
    },
    buttonText: {
      type: Object,
      default: () => ({
        syncText: 'sync',
        noSyncText: 'is syncing',
      }),
    },
    disableSync: {
      type: Boolean,
    },
  },
  data: () => ({
    timer: null,
    isSyncsUp: false,
    iniTime: null,
    deltaTime: 30000,
  }),
  computed: {
    endTime() {
      return this.$store.state.sync[`end${this.buttonStateName}`]
    },
  },
  created() {
    this.checkStorage()
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  methods: {
    checkStorage() {
      if (this.endTime && this.endTime > this.initTime) {
        this.deltaTime = this.endTime - this.initTime
        this.isSyncsUp = true

        this.timer = setTimeout(() => {
          this.isSyncsUp = false
          this.$store.commit(`set${this.buttonStateName}Empty`)
        }, this.deltaTime)
      } else {
        this.isSyncsUp = false
        this.$store.commit(`set${this.buttonStateName}Empty`)
        this.deltaTime = 30000
      }
    },
    synchronize() {
      this.isSyncsUp = true
      this.$store.commit(`set${this.buttonStateName}`, new Date())

      this.actionFunc({ boardId: this.boardId })
        .then((res) => {
          if (res === '200') {
            this.timer = setTimeout(() => {
              this.isSyncsUp = false
              this.$store.commit(`set${this.buttonStateName}Empty`)
            }, this.deltaTime)
          } else {
            this.isSyncsUp = false
            this.$store.commit(`set${this.buttonStateName}Empty`)
            this.deltaTime = 30000
          }
        })
        .catch(() => {
          this.isSyncsUp = false
          this.$store.commit(`set${this.buttonStateName}Empty`)
          this.deltaTime = 30000
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
